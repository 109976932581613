export const generalOperations = `
  query GetUserByProfileType($where: users_bool_exp!, $limit: Int = 30, $offset: Int = 0, $userId: Int = 0) {
    users(where: $where, order_by: {id: desc, isVerified: desc, isBetaUser: desc}, limit: $limit, offset: $offset) {
      lastname
      name
      username
      isWorker
      isVerified
      isBetaUser
      isActive
      isPartner
      isSponsored
      isFeatured
      id
      email
      country {
        name
        id
      }
      birthday
      bannerImage
      about
      aboutRich
      online
      profileImage
      lookingForJob
      showBirthday
      onboarded
      profileTypes(where: {isActive: {_eq: true}}) {
        profileType {
          name
          slug
          id
          category
          color
          icon
        }
        isActive
        order
      }
      socials(order_by: {order: asc}) {
        socialAccount {
          id
          name
          isActive
        }
        link
      }
      userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
        total: aggregate {
          count(columns: userId)
        }
      }
    }
    pagination: users_aggregate(where: $where) {
      data: aggregate {
        total: count(columns: id)
      }
    }
  }

  query GetUserMenu($userid: Int!) {
    userMenu: fn_user_menu(args: {userid: $userid}, order_by: {order: asc}) {
      count
      name
      section_id
      slug
      visible
      order
    }
  }  

  mutation InsertFile($file: String!, $id: Int!, $name: String!, $type: String!) {
    insertFile(payload: {id: $id, name: $name, file: $file, type: $type}) {
      imageUrl
      imageOg
      message
      success
    }
  }
`

export const homeOperations = `
  query GetFeatureUsers($userId: Int, $limit: Int = 5) {
    users(limit: $limit, where: {aboutRich: {_is_null: false}, about: {_is_null: false}, bannerImage: {_is_null: false}, profileImage: {_is_null: false}, isFeatured: {_eq: true}}, order_by: {isVerified: asc, isBetaUser: asc, id: desc, updatedAt: desc}) {
      lastname
      name
      username
      isWorker
      isVerified
      isBetaUser
      isActive
      isPartner
      isSponsored
      isFeatured
      id
      email
      country {
        name
        id
      }
      birthday
      bannerImage
      about
      aboutRich
      online
      profileImage
      lookingForJob
      showBirthday
      onboarded
      profileTypes(where: {isActive: {_eq: true}}) {
        profileType {
          name
          slug
          id
          category
          color
          icon
        }
        isActive
        order
      }
      socials(order_by: {order: asc}) {
        socialAccount {
          id
          name
          isActive
        }
        link
      }
      userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
        total: aggregate {
          count(columns: userId)
        }
      }
    }
  }

  query GetUsersMarquee($userId: Int, $limit: Int = 12) {
    users(limit: $limit, where: {aboutRich: {_is_null: false}, about: {_is_null: false}, bannerImage: {_is_null: false}, profileImage: {_is_null: false}}, order_by: {id: desc, updatedAt: desc}) {
      lastname
      name
      username
      isWorker
      isVerified
      isBetaUser
      isActive
      isPartner
      isSponsored
      isFeatured
      id
      email
      country {
        name
        id
      }
      birthday
      bannerImage
      about
      aboutRich
      online
      profileImage
      lookingForJob
      showBirthday
      onboarded
      profileTypes(where: {isActive: {_eq: true}}) {
        profileType {
          name
          slug
          id
          category
          color
          icon
        }
        isActive
        order
      }
      socials(order_by: {order: asc}) {
        socialAccount {
          id
          name
          isActive
        }
        link
      }
      userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
        total: aggregate {
          count(columns: userId)
        }
      }
    }
  }

  query GetUserByProfileTypeHome($userId: Int!, $limit: Int = 4, $offset: Int = 0) {
    profileTypes: profile_types(order_by: {user_profile_types_aggregate: {count: desc}}, where: {user_profile_types_aggregate: {count: {predicate: {_gt: 0}, arguments: id}}, user_profile_types: {isActive: {_eq: true}}}, limit: $limit, offset: $offset) {
      slug
      name
      isActive
      icon
      color
      category
      usersCounts: user_profile_types_aggregate(where: {isActive: {_eq: true}}) {
        total: aggregate {
          count(columns: userId)
        }
      }
      usersProfileTypes: user_profile_types(order_by: {user: {userFollowers_aggregate: {count: desc}, isVerified: desc, isBetaUser: desc}}, where: {isActive: {_eq: true}, profileType: {}}, limit: 3) {
        user {
          lastname
          name
          username
          isWorker
          isVerified
          isBetaUser
          isActive
          isPartner
          isSponsored
          isFeatured
          id
          email
          country {
            name
            id
          }
          birthday
          bannerImage
          about
          aboutRich
          online
          profileImage
          lookingForJob
          showBirthday
          onboarded
          profileTypes(where: {isActive: {_eq: true}}) {
            profileType {
              name
              slug
              id
              category
              color
              icon
            }
            isActive
            order
          }
          socials(order_by: {order: asc}) {
            socialAccount {
              id
              name
              isActive
            }
            link
          }
          userFollower: userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
            total: aggregate {
              count(columns: userId)
            }
          }
        }
      }
    }
    pagination: profile_types_aggregate(where: {user_profile_types_aggregate: {count: {predicate: {_gt: 0}, arguments: userId}}, user_profile_types: {isActive: {_eq: true}}}) {
      data: aggregate {
        total: count(columns: id)
      }
    }
  }
`

export const onboardingOperations = `
  query GetOnBoardingRecommendedByUser($id: Int!) {
    users_by_pk(id: $id) {
      games {
        game {
          users(where: {user: {id: {_neq: $id}, profileImage: {_neq: ""}}}) {
            user {
              username
              id
              profileImage
              name
              isVerified
              isBetaUser
            }
          }
        }
      }
      profileTypes {
        profileType {
          user_profile_types(where: {user: {id: {_neq: $id}, profileImage: {_neq: ""}}}) {
            user {
              username
              id
              profileImage
              name
              isVerified
              isBetaUser
            }
          }
        }
      }
    }
  }
`