import { ApgUser } from '@/domain/account.interface';
import { create } from 'zustand'

interface FormData {
  name: string;
  email: string;
  birthday: string;
  countryId: string;
  username: string;
  password: string;
}

type Store = {
  authToken: string;
  temporaryData: FormData;
  userId: number | undefined;
  isLogged: boolean;
  userData: ApgUser | undefined;
  setAuthtoken: (token: string) => void;
  setFormData: (data: Partial<FormData>) => void;
  setUserId: (userId: number) => void;
  setUserData: (userData: ApgUser) => void;
  setIsLogged: (isLogged: boolean) => void;
}

const useAuthStore = create<Store>((set) => {
  return {
    authToken: '',
    temporaryData: {
      name: '',
      email: '',
      birthday: '',
      countryId: '',
      username: '',
      password: '',
    },
    userId: undefined,
    userData: undefined,
    isLogged: false,
    setAuthtoken: (token: string) => set(state => ({ ...state, authToken: token })),
    setFormData: (data: Partial<FormData>) => set(state => ({ ...state, temporaryData: { ...state.temporaryData, ...data }})),
    setUserId: (userId: number) => set(state => ({ ...state, userId })),
    setUserData: (userData: ApgUser) => set(state => ({ ...state, userData })),
    setIsLogged: (isLogged: boolean) => set(state => ({ ...state, isLogged })),
  }
});

export default useAuthStore;