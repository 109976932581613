export const operationFollows = `
  query GetUserFollowers($followId: Int!, $userId: Int!, $limit: Int = 30, $offset: Int = 0) {
    userFollowers: user_follows(where: {followingId: {_eq: $followId}}, limit: $limit, offset: $offset) {
      id
      user {
        about
        aboutRich
        bannerImage
        id
        name
        profileImage
        username
        onboarded
        userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
          aggregate {
            count(columns: userId)
          }
        }
        userFollowing_aggregate(where: {followingId: {_eq: $userId}}) {
          aggregate {
            count(columns: userId)
          }
        }
        isVerified
        isBetaUser
        isPartner
        isSponsored
        isFeatured
        isWorker
      }
    }
    pagination: user_follows_aggregate(where: {followingId: {_eq: $followId}}) {
      total: aggregate {
        count(columns: userId)
      }
    }
  }

  query GetUserFollowings($followId: Int!, $userId: Int!, $limit: Int = 30, $offset: Int = 0) {
    userFollowings: user_follows(where: {userId: {_eq: $followId}}, limit: $limit, offset: $offset) {
      id
      following {
        about
        aboutRich
        bannerImage
        id
        name
        profileImage
        username
        onboarded
        userFollowers_aggregate(where: {userId: {_eq: $userId}}) {
          aggregate {
            count(columns: userId)
          }
        }
        userFollowing_aggregate(where: {followingId: {_eq: $userId}}) {
          aggregate {
            count(columns: userId)
          }
        }
        isVerified
        isBetaUser
        isPartner
        isSponsored
        isFeatured
        isWorker
      }
    }
    pagination: user_follows_aggregate(where: {userId: {_eq: $followId}}) {
      total: aggregate {
        count(columns: userId)
      }
    }
  }  
`